import "./IconFalconEye.css";

function IconFalcon(props: any) {
  return (
    <svg viewBox="0 0 654.18665 341.42667" style={{fill: 'rgb(229, 51, 36)'}}>
      <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,341.42667)">
        <g id="g12" transform="scale(0.1)">
          <path
            d="m 2828.45,1083.16 c -35.08,3.49 -97.3,12.14 -175.2,-27.12 -77.83,-39.25 -108.47,-40.95 -146.68,-36.88 11.24,-20.531 33.98,-48.738 105.55,-53.769 71.57,-5.039 105.78,-7.262 68.12,-96.43 0.88,26.93 -5.41,79.027 -76.47,69.879 -71.01,-9.16 -87.61,-73.078 -11.41,-105 -24.79,-4.981 -77.3,-7.942 -114.81,90.191 -25.99,-11.293 -66.05,-33.922 -138.69,22.188 25.3,-9.18 56.61,-9.77 56.61,-9.77 -64.5,30.789 -126.06,88.031 -165.54,142.121 31.3,-23.28 65.82,-46.52 100.73,-50.86 -41.25,50.33 -136.66,150.98 -253.41,254.4 75.07,-49.01 165.65,-126.51 313.83,-109.08 148.23,17.43 247.84,51.4 437.37,-89.87"
            id="path14"
          />
          <path
            d="m 2194.07,1118.1 c -92.96,40.28 -112.86,48.36 -232.36,78.74 -119.52,30.36 -237.06,93.53 -315.64,192.14 55.42,-40.59 168.54,-122.23 284.77,-113.39 -17.68,25.85 -50.24,46 -89.2,66.32 44.04,-10.56 176.95,-44.55 352.43,-223.81"
            id="path16"
          />
          <path
            d="m 1991.71,1462.1 c -23.98,68.75 -67.19,156.78 -272.23,287.56 -99.87,65.88 -246.46,148.64 -439.5,359.68 13.8,-56.88 74.79,-204.8 382.13,-396.85 100.97,-68.87 231.26,-111.38 329.6,-250.39"
            id="path18"
          />
          <path
            d="m 2004.39,1362.45 c -25.22,58.19 -75.92,132.84 -274.54,239.42 -91.6,51.29 -248.07,130.24 -388.8,280.32 12.77,-54.18 78.08,-173.21 359.18,-321.86 77.85,-42.87 209.43,-83.1 304.16,-197.88"
            id="path20"
          />
          <path
            d="m 2197.04,1663.22 c -387.17,110.77 -541.16,250.58 -660.26,396.97 54.39,-168.48 184.11,-228.76 322.69,-341.87 138.57,-113.12 146.46,-173.89 187.29,-240.72 90.92,-148.89 105.39,-173.48 195.65,-238.47 106.38,-70.58 235.03,-22.83 376.15,-44.97 141.11,-22.11 257.74,-129.66 283.13,-171.07 29.58,52.77 -41.1,128.83 -60.17,147.92 10.17,69.14 -153.29,99.51 -215.2,123.17 -12.13,4.64 -42.23,11.61 -16.14,73.66 35.18,85.63 71.84,160.13 -413.14,295.38 m 558.78,-454.27 c 73.41,-11.07 69.72,-26.66 70.49,-53.48 -31.22,34.11 -70.49,53.48 -70.49,53.48"
            id="path22"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconFalcon;
