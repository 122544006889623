function IconPlay() {
    return (
      <svg
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        style={{ fill: "green", stroke: "green", width: '24px' }}
      >
        <path d="M8 5v14l11-7z"></path>
      </svg>
    );
  }
  
  export default IconPlay;